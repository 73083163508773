<template>
<div class="legend">
    <div class="legend-line" style="margin-top: 8px">
      <div class="sun-l"><img width="15px" src="/sunrise.svg"/> {{ $t('legends.sunrise') }}</div>
      <div class="sun-l"><img width="15px" src="/sunset.svg"/> {{ $t('legends.sunset') }}</div>
    </div>
    <div>
      {{ $t('legends.ta') }} : {{ $t('legends.taLong') }}  - {{ $t('legends.td') }} : {{ $t('legends.tdLong') }} - {{ $t('legends.u') }} : {{ $t('legends.uLong') }}
      <template v-if="road"> - {{ $t('legends.ts') }} : {{ $t('legends.tsLong') }} - {{ $t('legends.rc') }} : {{ $t('legends.rcLong') }}</template>
      - {{ $t('legends.wdir') }} : {{ $t('legends.wdirLong') }}
    </div>
    <div >{{ roadCondUsed }}</div>
    <div class="legend-line">
      <div v-if="hasAlertSevere" style="margin-right: 9px"><span  class="highlight-red">date : </span>{{ $t('legends.severe') }}</div>
      <div v-if="hasRainFrz" style="margin-right: 9px"><span  class="highlight-orange">0.00 : </span>{{ $t('legends.rainfrz') }}</div>
      <div v-if="hasIce" style="margin-right: 9px"><span  class="highlight-blue">0.00 : </span>{{ $t('legends.ice') }}</div>
    </div>
</div>
</template>

<script>
import { rc } from "@/road_cond";

export default {
  name: "Legends",
  props: {
    "data": {
      type: Array
    },
    "alerts": {
      type: Array
    },
    "road": {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasAlertSevere() {
      return this.alerts && this.alerts.find(a => a.source == 'MG' && a.severity>2);
    },
    hasRainFrz() {
      return this.data && this.data.find(d=> d.rain_frz && d.rain_frz>0)
    },
    hasIce() {
      return this.data && this.data.find(d=> d.ice && d.ice>0)
    },
    roadCondUsed() {
      let rcUsed = new Set();

      if(!this.data) return '';

      this.data.map(d=> {
        if(d.road_cond) rcUsed.add(d.road_cond)
      });

      let result = '';

      if(rcUsed.size>0) {
        Array.from(rcUsed).map(rcCode => {
          let rc = this.rc(rcCode);
          if (result.length) result += ' - ';
          result += this.$t('roadConds.'+ rc.id + '.short')  + ' : ' + this.$t('roadConds.'+ rc.id + '.text');
        })
      }

      return result;
    }
  },
  methods: {
    rc,
  }
}
</script>

<style lang="scss">
.legend {
    padding-left: 3% !important;
    font-size: 10px !important;

    .sun-l {
        margin-right: 10px;
        margin-left: -4px;
        img {
          transform: translate(0px, 3px);
        }
    }

    .legend-line {
      display: flex;
    }
}
</style>
