
const table = [
    {
        id:"DRY",
        color:"#10B457"
    },
    {
        id:"WET",
        color:"#FFEF14"
    },
    {
        id:"SNOW_ICE",
        color:"#FF4B3E"
    },
    {
        id:"MELTING_SNOW",
        color:" #FFB20F"
    },
    {
        id:"WATER+SNOW",
        color:"#FFEF14"
    },
    {
        id:"DEW",
        color:"#FFEF14"
    },
    {
        id:"FROST",
        color:"#FF4B3E"
    },
    {
        id:"ICING_RAIN",
        color:"#FF4B3E"
    },
    ]
    export const rc = id => {
        let rc = table.find(map=>map.id == id)
        if(!rc){
            rc = {};
            rc.color = "primary"
            rc.text = "-"
        }
        return rc;
    };

    export default rc;
