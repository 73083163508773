<template>
  <div>
    <div v-if="this.loaded" class="home">
      <div class="header">
        <div><img :src="logoBrand" /></div>
        <div>
          <div>
            <h1>
              {{ $t("global.weatherReport") + " - " + castInfos.point.name }}
            </h1>
          </div>
          <small> {{ castInfosLabel }}</small>
        </div>
        <div><img :src="setup.logo" /></div>
      </div>

      <div class="humancast part">
        <div class="header-part">
          <h2
            v-if="
              humanCast &&
                humanCast.weather_short &&
                humanCast.weather_short.text
            "
            v-html="
              $t('bulletin.title', {
                date: $moment(
                  humanCast.weather_short.time_publish * 1000
                ).format('ddd DD MMM YYYY - HH:mm'),
              })
            "
          />
          <h2 v-else>
            {{ $t("bulletin.titleEmpty") }}
          </h2>
        </div>
        <div class="text-part">
          <vue-markdown
            v-if="
              humanCast &&
                humanCast.weather_short &&
                humanCast.weather_short.text
            "
            :source="humanCast.weather_short.text"
            class="bulletin__text"
          />
          <p v-else>
            {{ $t("global.missing") }}
          </p>
        </div>
      </div>

      <div class="alerts part">
        <div class="header-part">
          <h2>
            <div>{{ $t("alerts.title") }}</div>
            <div
              v-if="alerts && alerts.length > 0"
              style="font-size: 12px;font-weight: normal"
            >
              {{ $t("alerts.note") }}
            </div>
          </h2>
        </div>
        <div class="text-part" v-if="!alerts">
          <p>
            {{ $t("global.missing") }}
          </p>
        </div>
        <div class="text-part" v-else-if="alerts.length == 0">
          <p>
            {{ $t("alerts.none") }}
          </p>
        </div>
        <div class="content-part" v-else-if="alerts.length > 0">
          <table border="1">
            <tbody>
              <tr class="table-header">
                <td>{{ $t("alerts.type") }}</td>
                <td>{{ $t("alerts.severity") }}</td>
                <td v-html="$t('alerts.source')"></td>
                <td>{{ $t("alerts.start") }}</td>
                <td>{{ $t("alerts.end") }}</td>
              </tr>
              <tr v-for="(chunk, i) in alerts" :key="i">
                <td>{{ getAlertTypeText(chunk.type) }}</td>

                <td
                  v-if="chunk.source == 'MG' && chunk.severity > 2"
                  class="highlight-red"
                >
                  {{ getAlertSeverity(chunk.severity) }}<sup>(2)</sup>
                </td>
                <td v-else>{{ getAlertSeverity(chunk.severity) }}</td>

                <td>{{ getAlertSource(chunk.source, "short") }}</td>
                <td>
                  {{
                    $moment(chunk.time_start * 1000).format("dddd DD MMM HH:mm")
                  }}
                </td>
                <td>
                  {{
                    $moment(chunk.time_end * 1000).format("dddd DD MMM HH:mm")
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="(legends = getAlertsLegends(alerts))"
            style="margin-top:6px"
          >
            <small><sup>(1)</sup> {{ legends.join(", ") }}</small>
          </div>
          <div
            v-if="
              alerts.find((a) => a.source == 'MG' && a.severity > 2) != null
            "
            class="highlight-red"
          >
            <small><sup>(2)</sup> {{ $t("alerts.severe") }}</small>
          </div>
        </div>
      </div>

      <div class="oneHourCasts casts part break-before" v-if="castInfos.point">
        <div class="header-part">
          <h2>
            {{ $t("forecasts.label") }} {{ castInfos.point.name }}
            <small>{{ castInfosLabel }}</small>
          </h2>
        </div>
        <div class="content-part">
          <table border="1">
            <tbody>
              <tr>
                <td class="title" colspan="7">
                  <span class="bullet"></span>
                  <span
                    >{{ $t("forecasts.oneHour") }} <small>0 - 12h</small></span
                  >
                </td>
                <td class="maj" colspan="7">
                  <span
                    >{{ $t("forecasts.update") }}
                    {{
                      $moment(castInfos.updateOne * 1000).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    }}</span
                  >
                </td>
              </tr>
              <tr class="casts-alerts">
                <td
                  colspan="14"
                  v-if="oneHourCastsAlerts && oneHourCastsAlerts.length"
                >
                  <h3>{{ $t("alerts.titleCast") }}</h3>
                  <table class="alerts">
                    <thead>
                      <th style="min-width: 80px;">
                        {{ $t("forecasts.from") }}
                      </th>
                      <th style="min-width: 80px;">{{ $t("forecasts.to") }}</th>
                      <th>{{ $t("forecasts.details") }}</th>
                    </thead>
                    <tr v-for="(alert, i) in oneHourCastsAlerts" :key="i">
                      <template
                        v-if="alert.severity > 2 && alert.source == 'MG'"
                      >
                        <td>
                          <span
                            class="casts-alert__value"
                            style="font-weight:bold;color: red"
                            >{{
                              $moment(alert.time_start * 1000).format(
                                "DD/MM HH:mm"
                              )
                            }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="casts-alert__value"
                            style="font-weight:bold;color: red"
                            >{{
                              $moment(alert.time_end * 1000).format(
                                "DD/MM HH:mm"
                              )
                            }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="casts-alert__severe"
                            v-html="
                              alert.message.replace(/(?:\r\n|\r|\n)/g, '<br>')
                            "
                          ></span>
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          <span class="casts-alert__value">{{
                            $moment(alert.time_start * 1000).format(
                              "DD/MM HH:mm"
                            )
                          }}</span>
                        </td>
                        <td>
                          <span class="casts-alert__value">{{
                            $moment(alert.time_end * 1000).format("DD/MM HH:mm")
                          }}</span>
                        </td>
                        <td>
                          <span
                            class="casts-alert__header"
                            v-html="$t('alerts.source') + ' : '"
                          /><span class="casts-alert__value">{{
                            getAlertSource(alert.source, "short")
                          }}</span>
                          <span class="casts-alert__header"
                            >{{ $t("alerts.type") }} : </span
                          ><span class="casts-alert__value">{{
                            getAlertTypeText(alert.type)
                          }}</span>
                          <span class="casts-alert__header"
                            >{{ $t("alerts.severity") }} :
                          </span>
                          <span class="casts-alert__value">{{
                            getAlertSeverity(alert.severity)
                          }}</span>
                        </td>
                      </template>
                    </tr>
                  </table>
                </td>
                <td colspan="14" v-else>
                  <h3>{{ $t("alerts.titleCast") }}</h3>
                  <p>{{ $t("alerts.none") }}</p>
                </td>
              </tr>
              <tr class="table-header">
                <td rowspan="3">{{ $t("forecasts.date") }}</td>
                <td colspan="4">{{ $t("forecasts.airMass") }}</td>
                <td colspan="3">{{ $t("forecasts.precip") }}</td>
                <td v-if="road" colspan="2">{{ $t("forecasts.road") }}</td>
                <td colspan="3">{{ $t("forecasts.wind") }}</td>
              </tr>
              <tr>
                <td rowspan="2">{{ $t("forecasts.picto") }}</td>
                <td rowspan="2" v-html="$t('forecasts.ta')"></td>
                <td rowspan="2" v-html="$t('forecasts.td')"></td>
                <td rowspan="2" v-html="$t('forecasts.u')"></td>
                <td colspan="2">{{ $t("forecasts.acc") }}</td>
                <td rowspan="2" v-html="$t('forecasts.spatial')"></td>
                <td v-if="road" rowspan="2" v-html="$t('forecasts.troad')"></td>
                <td v-if="road" rowspan="2">{{ $t("forecasts.rc") }}</td>
                <td rowspan="2">{{ $t("forecasts.wdir") }}</td>
                <td rowspan="2">
                  {{ $t("forecasts.wavg") }}<br /><span class="unit--small"
                    >(km/h)</span
                  >
                </td>
                <td rowspan="2">
                  {{ $t("forecasts.wmax") }}<br /><span class="unit--small"
                    >(km/h)</span
                  >
                </td>
              </tr>
              <tr>
                <td>{{ $t("forecasts.rain") }}<br />(mm)</td>
                <td>{{ $t("forecasts.snow") }}<br />(cm)</td>
              </tr>
              <tr v-for="(chunk, i) in oneHourCasts" :key="i">
                <td style="min-width: 130px">
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).length > 0
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ $moment(chunk.time * 1000).format(dateFormat) }}</span
                  ><br />
                  <span v-html="isRise(chunk)"></span>
                  <span v-html="isSet(chunk)"></span>
                </td>
                <td>
                  <img
                    class="cast-picto"
                    :src="iconUrl(chunk.picto, chunk.isNight)"
                  />
                </td>
                <td>{{ roundToTwo(chunk.tair) }}</td>
                <td>{{ roundToTwo(chunk.tdew) }}</td>
                <td>{{ roundToTwo(chunk.rh) }}</td>
                <td>
                  {{ roundToTwo(chunk.rain) }}
                  <div
                    v-if="chunk.rain_frz > 0"
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'rain_frz'
                      )
                        ? 'highlight-red'
                        : 'highlight-orange'
                    "
                  >
                    {{ roundToTwo(chunk.rain_frz) }}
                  </div>
                </td>
                <td>
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'snow'
                      )
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ roundToTwo(chunk.snow) }}</span
                  >
                  <div
                    v-if="chunk.ice > 0"
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'ice'
                      )
                        ? 'highlight-red'
                        : 'highlight-blue'
                    "
                  >
                    {{ roundToTwo(chunk.ice) }}
                  </div>
                </td>
                <td>
                  {{
                    chunk.pb_precip >= 0
                      ? Math.floor(chunk.pb_precip * 100)
                      : "-"
                  }}
                </td>
                <td v-if="road">
                  {{ !isNaN(chunk.troad) ? roundToTwo(chunk.troad) : "-" }}
                </td>
                <td v-if="road">
                  {{
                    chunk.road_cond
                      ? $t("roadConds." + chunk.road_cond + ".short")
                      : "-"
                  }}
                </td>
                <td>{{ $t("windDir." + cardinals(chunk.w_dir)) }}</td>
                <td>
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'snow'
                      )
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ chunk.w_avg }}</span
                  >
                </td>
                <td>{{ chunk.w_max }}</td>
              </tr>
              <tr>
                <td colspan="5">{{ $t("forecasts.total") }}</td>
                <td>
                  {{ getTotalRain(oneHourCasts) }}
                  <div
                    v-if="getTotalRainFrz(oneHourCasts) > 0"
                    class="highlight-orange"
                  >
                    {{ getTotalRainFrz(oneHourCasts) }}
                  </div>
                </td>
                <td>
                  {{ getTotalSnow(oneHourCasts) }}
                  <div
                    v-if="getTotalIce(oneHourCasts) > 0"
                    class="highlight-blue"
                  >
                    {{ getTotalIce(oneHourCasts) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Legend
            :data="oneHourCasts"
            :alerts="oneHourCastsAlerts"
            :road="road"
          />
          <div
            v-if="(legends = getAlertsLegends(oneHourCastsAlerts))"
            style="padding-left: 3% !important; font-size: 10px !important"
          >
            {{ legends.join(", ") }}
          </div>
        </div>
      </div>

      <div
        class="threeHourCasts casts part break-before"
        v-if="castInfos.point"
      >
        <div class="header-part">
          <h2>
            {{ $t("forecasts.label") }} {{ castInfos.point.name }}
            <small>{{ castInfosLabel }} </small>
          </h2>
        </div>
        <div class="content-part">
          <table border="1">
            <tbody>
              <tr>
                <td class="title" colspan="7">
                  <span class="bullet"></span>
                  <span
                    >{{ $t("forecasts.threeHour") }}
                    <small>12 - 48h</small></span
                  >
                </td>
                <td class="maj" colspan="7">
                  <span
                    >{{ $t("forecasts.update") }}
                    {{
                      $moment(castInfos.updateThree * 1000).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    }}</span
                  >
                </td>
              </tr>
              <tr class="casts-alerts">
                <td
                  colspan="14"
                  v-if="threeHoursCastsAlerts && threeHoursCastsAlerts.length"
                >
                  <h3>{{ $t("alerts.titleCast") }}</h3>
                  <table class="alerts">
                    <thead>
                      <th style="min-width: 80px;">
                        {{ $t("forecasts.from") }}
                      </th>
                      <th style="min-width: 80px;">{{ $t("forecasts.to") }}</th>
                      <th>{{ $t("forecasts.details") }}</th>
                    </thead>
                    <tr v-for="(alert, i) in threeHoursCastsAlerts" :key="i">
                      <template
                        v-if="alert.severity > 2 && alert.source == 'MG'"
                      >
                        <td>
                          <span
                            class="casts-alert__value"
                            style="font-weight:bold;color: red"
                            >{{
                              $moment(alert.time_start * 1000).format(
                                "DD/MM HH:mm"
                              )
                            }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="casts-alert__value"
                            style="font-weight:bold;color: red"
                            >{{
                              $moment(alert.time_end * 1000).format(
                                "DD/MM HH:mm"
                              )
                            }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="casts-alert__severe"
                            v-html="
                              alert.message.replace(/(?:\r\n|\r|\n)/g, '<br>')
                            "
                          ></span>
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          <span class="casts-alert__value">{{
                            $moment(alert.time_start * 1000).format(
                              "DD/MM HH:mm"
                            )
                          }}</span>
                        </td>
                        <td>
                          <span class="casts-alert__value">{{
                            $moment(alert.time_end * 1000).format("DD/MM HH:mm")
                          }}</span>
                        </td>
                        <td>
                          <span
                            class="casts-alert__header"
                            v-html="$t('alerts.source') + ' : '"
                          /><span class="casts-alert__value">{{
                            getAlertSource(alert.source, "short")
                          }}</span>
                          <span class="casts-alert__header"
                            >{{ $t("alerts.type") }} : </span
                          ><span class="casts-alert__value">{{
                            getAlertTypeText(alert.type)
                          }}</span>
                          <span class="casts-alert__header"
                            >{{ $t("alerts.severity") }} :
                          </span>
                          <span class="casts-alert__value">{{
                            getAlertSeverity(alert.severity)
                          }}</span>
                        </td>
                      </template>
                    </tr>
                  </table>
                </td>
                <td colspan="14" v-else>
                  <h3>{{ $t("alerts.titleCast") }}</h3>
                  <p>{{ $t("alerts.none") }}</p>
                </td>
              </tr>
              <tr class="table-header">
                <td rowspan="3">{{ $t("forecasts.date") }}</td>
                <td colspan="4">{{ $t("forecasts.airMass") }}</td>
                <td colspan="3">{{ $t("forecasts.precip") }}</td>
                <td v-if="road" colspan="2">{{ $t("forecasts.road") }}</td>
                <td colspan="3">{{ $t("forecasts.wind") }}</td>
              </tr>
              <tr>
                <td rowspan="2">{{ $t("forecasts.picto") }}</td>
                <td rowspan="2" v-html="$t('forecasts.ta')"></td>
                <td rowspan="2" v-html="$t('forecasts.td')"></td>
                <td rowspan="2" v-html="$t('forecasts.u')"></td>
                <td colspan="2">{{ $t("forecasts.acc") }}</td>
                <td rowspan="2" v-html="$t('forecasts.spatial')"></td>
                <td v-if="road" rowspan="2" v-html="$t('forecasts.troad')"></td>
                <td v-if="road" rowspan="2">{{ $t("forecasts.rc") }}</td>
                <td rowspan="2">{{ $t("forecasts.wdir") }}</td>
                <td rowspan="2">
                  {{ $t("forecasts.wavg") }}<br /><span class="unit--small"
                    >(km/h)</span
                  >
                </td>
                <td rowspan="2">
                  {{ $t("forecasts.wmax") }}<br /><span class="unit--small"
                    >(km/h)</span
                  >
                </td>
              </tr>
              <tr>
                <td>{{ $t("forecasts.rain") }}<br />(mm)</td>
                <td>{{ $t("forecasts.snow") }}<br />(cm)</td>
              </tr>
              <tr v-for="(chunk, i) in threeHoursCasts" :key="i">
                <td style="min-width: 130px">
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).length > 0
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ $moment(chunk.time * 1000).format(dateFormat) }}</span
                  ><br />
                  <span v-html="isRise(chunk)"></span>
                  <span v-html="isSet(chunk)"></span>
                </td>
                <td>
                  <img
                    class="cast-picto"
                    :src="iconUrl(chunk.picto, chunk.isNight)"
                  />
                </td>
                <td>{{ roundToTwo(chunk.tair) }}</td>
                <td>{{ roundToTwo(chunk.tdew) }}</td>
                <td>{{ roundToTwo(chunk.rh) }}</td>
                <td>
                  {{ roundToTwo(chunk.rain) }}
                  <div
                    v-if="chunk.rain_frz > 0"
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'rain_frz'
                      )
                        ? 'highlight-red'
                        : 'highlight-orange'
                    "
                  >
                    {{ roundToTwo(chunk.rain_frz) }}
                  </div>
                </td>
                <td>
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'snow'
                      )
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ roundToTwo(chunk.snow) }}</span
                  >
                  <div
                    v-if="chunk.ice > 0"
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'ice'
                      )
                        ? 'highlight-red'
                        : 'highlight-blue'
                    "
                  >
                    {{ roundToTwo(chunk.ice) }}
                  </div>
                </td>
                <td>
                  {{
                    chunk.pb_precip >= 0
                      ? Math.floor(chunk.pb_precip * 100)
                      : "-"
                  }}
                </td>
                <td v-if="road">
                  {{ !isNaN(chunk.troad) ? roundToTwo(chunk.troad) : "-" }}
                </td>
                <td v-if="road">
                  {{
                    chunk.road_cond
                      ? $t("roadConds." + chunk.road_cond + ".short")
                      : "-"
                  }}
                </td>
                <td>{{ $t("windDir." + cardinals(chunk.w_dir)) }}</td>
                <td>
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'w_avg'
                      )
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ chunk.w_avg }}</span
                  >
                </td>
                <td>{{ chunk.w_max }}</td>
              </tr>
              <tr>
                <td colspan="5">{{ $t("forecasts.total") }}</td>
                <td>
                  {{ getTotalRain(threeHoursCasts) }}
                  <div
                    v-if="getTotalRainFrz(threeHoursCasts) > 0"
                    class="highlight-orange"
                  >
                    {{ getTotalRainFrz(threeHoursCasts) }}
                  </div>
                </td>
                <td>
                  {{ getTotalSnow(threeHoursCasts) }}
                  <div
                    v-if="getTotalIce(threeHoursCasts) > 0"
                    class="highlight-blue"
                  >
                    {{ getTotalIce(threeHoursCasts) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Legend
            :data="threeHoursCasts"
            :alerts="threeHoursCastsAlerts"
            :road="road"
          />
          <div
            v-if="(legends = getAlertsLegends(threeHoursCastsAlerts))"
            style="padding-left: 3% !important; font-size: 10px !important"
          >
            {{ legends.join(", ") }}
          </div>
        </div>
      </div>

      <div class="sixHourCasts casts part break-before" v-if="castInfos.point">
        <div class="header-part">
          <h2>
            {{ $t("forecasts.label") }} {{ castInfos.point.name }}
            <small>{{ castInfosLabel }}</small>
          </h2>
        </div>
        <div class="content-part">
          <table border="1">
            <tbody>
              <tr>
                <td class="title" colspan="7">
                  <span class="bullet"></span>
                  <span
                    >{{ $t("forecasts.sixHour") }}
                    <small>48 - 120h</small></span
                  >
                </td>
                <td class="maj" colspan="7">
                  <span
                    >{{ $t("forecasts.update") }}
                    {{
                      $moment(castInfos.updateSix * 1000).format(
                        "DD/MM/YYYY à HH:mm"
                      )
                    }}</span
                  >
                </td>
              </tr>
              <tr class="casts-alerts">
                <td
                  colspan="14"
                  v-if="sixHoursCastsAlerts && sixHoursCastsAlerts.length"
                >
                  <h3>{{ $t("alerts.titleCast") }}</h3>
                  <table class="alerts">
                    <thead>
                      <th style="min-width: 80px;">
                        {{ $t("forecasts.from") }}
                      </th>
                      <th style="min-width: 80px;">{{ $t("forecasts.to") }}</th>
                      <th>{{ $t("forecasts.details") }}</th>
                    </thead>
                    <tr v-for="(alert, i) in sixHoursCastsAlerts" :key="i">
                      <template
                        v-if="alert.severity > 2 && alert.source == 'MG'"
                      >
                        <td>
                          <span
                            class="casts-alert__value"
                            style="font-weight:bold;color: red"
                            >{{
                              $moment(alert.time_start * 1000).format(
                                "DD/MM HH:mm"
                              )
                            }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="casts-alert__value"
                            style="font-weight:bold;color: red"
                            >{{
                              $moment(alert.time_end * 1000).format(
                                "DD/MM HH:mm"
                              )
                            }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="casts-alert__severe"
                            v-html="
                              alert.message.replace(/(?:\r\n|\r|\n)/g, '<br>')
                            "
                          ></span>
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          <span class="casts-alert__value">{{
                            $moment(alert.time_start * 1000).format(
                              "DD/MM HH:mm"
                            )
                          }}</span>
                        </td>
                        <td>
                          <span class="casts-alert__value">{{
                            $moment(alert.time_end * 1000).format("DD/MM HH:mm")
                          }}</span>
                        </td>
                        <td>
                          <span
                            class="casts-alert__header"
                            v-html="$t('alerts.source') + ' : '"
                          /><span class="casts-alert__value">{{
                            getAlertSource(alert.source, "short")
                          }}</span>
                          <span class="casts-alert__header"
                            >{{ $t("alerts.type") }} : </span
                          ><span class="casts-alert__value">{{
                            getAlertTypeText(alert.type)
                          }}</span>
                          <span class="casts-alert__header"
                            >{{ $t("alerts.severity") }} :
                          </span>
                          <span class="casts-alert__value">{{
                            getAlertSeverity(alert.severity)
                          }}</span>
                        </td>
                      </template>
                    </tr>
                  </table>
                </td>
                <td colspan="14" v-else>
                  <h3>{{ $t("alerts.titleCast") }}</h3>
                  <p>{{ $t("alerts.none") }}</p>
                </td>
              </tr>
              <tr class="table-header">
                <td rowspan="3">{{ $t("forecasts.date") }}</td>
                <td colspan="4">{{ $t("forecasts.airMass") }}</td>
                <td colspan="3">{{ $t("forecasts.precip") }}</td>
                <td v-if="road" colspan="2">{{ $t("forecasts.road") }}</td>
                <td colspan="3">{{ $t("forecasts.wind") }}</td>
              </tr>
              <tr>
                <td rowspan="2">{{ $t("forecasts.picto") }}</td>
                <td rowspan="2" v-html="$t('forecasts.ta')"></td>
                <td rowspan="2" v-html="$t('forecasts.td')"></td>
                <td rowspan="2" v-html="$t('forecasts.u')"></td>
                <td colspan="2">{{ $t("forecasts.acc") }}</td>
                <td rowspan="2" v-html="$t('forecasts.spatial')"></td>
                <td v-if="road" rowspan="2" v-html="$t('forecasts.troad')"></td>
                <td v-if="road" rowspan="2">{{ $t("forecasts.rc") }}</td>
                <td rowspan="2">{{ $t("forecasts.wdir") }}</td>
                <td rowspan="2">
                  {{ $t("forecasts.wavg") }}<br /><span class="unit--small"
                    >(km/h)</span
                  >
                </td>
                <td rowspan="2">
                  {{ $t("forecasts.wmax") }}<br /><span class="unit--small"
                    >(km/h)</span
                  >
                </td>
              </tr>
              <tr>
                <td>{{ $t("forecasts.rain") }}<br />(mm)</td>
                <td>{{ $t("forecasts.snow") }}<br />(cm)</td>
              </tr>
              <tr v-for="(chunk, i) in sixHoursCasts" :key="i">
                <td style="min-width: 130px">
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).length > 0
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ $moment(chunk.time * 1000).format(dateFormat) }}</span
                  ><br />
                  <span v-html="isRise(chunk)"></span>
                  <span v-html="isSet(chunk)"></span>
                </td>
                <td>
                  <img
                    class="cast-picto"
                    :src="iconUrl(chunk.picto, chunk.isNight)"
                  />
                </td>
                <td>{{ roundToTwo(chunk.tair) }}</td>
                <td>{{ roundToTwo(chunk.tdew) }}</td>
                <td>{{ roundToTwo(chunk.rh) }}</td>
                <td>
                  {{ roundToTwo(chunk.rain) }}
                  <div
                    v-if="chunk.rain_frz > 0"
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'rain_frz'
                      )
                        ? 'highlight-red'
                        : 'highlight-orange'
                    "
                  >
                    {{ roundToTwo(chunk.rain_frz) }}
                  </div>
                </td>
                <td>
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'snow'
                      )
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ roundToTwo(chunk.snow) }}</span
                  >
                  <div
                    v-if="chunk.ice > 0"
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'ice'
                      )
                        ? 'highlight-red'
                        : 'highlight-blue'
                    "
                  >
                    {{ roundToTwo(chunk.ice) }}
                  </div>
                </td>
                <td>
                  {{
                    chunk.pb_precip >= 0
                      ? Math.floor(chunk.pb_precip * 100)
                      : "-"
                  }}
                </td>
                <td v-if="road">
                  {{
                    !isNaN(chunk.troad_min) ? roundToTwo(chunk.troad_min) : "-"
                  }}
                </td>
                <td v-if="road">
                  {{
                    chunk.road_cond_worst
                      ? $t("roadConds." + chunk.road_cond_worst + ".short")
                      : "-"
                  }}
                </td>
                <td>{{ $t("windDir." + cardinals(chunk.w_dir)) }}</td>
                <td>
                  <span
                    :class="
                      alertsSevereOfTime(chunk.time, chunk.duration).includes(
                        'w_avg'
                      )
                        ? 'highlight-red'
                        : ''
                    "
                    >{{ chunk.w_avg }}</span
                  >
                </td>
                <td>{{ chunk.w_max }}</td>
              </tr>
              <tr>
                <td colspan="5">{{ $t("forecasts.total") }}</td>
                <td>
                  {{ getTotalRain(sixHoursCasts) }}
                  <div
                    v-if="getTotalRainFrz(sixHoursCasts) > 0"
                    class="highlight-orange"
                  >
                    {{ getTotalRainFrz(sixHoursCasts) }}
                  </div>
                </td>
                <td>
                  {{ getTotalSnow(sixHoursCasts) }}
                  <div
                    v-if="getTotalIce(sixHoursCasts) > 0"
                    class="highlight-blue"
                  >
                    {{ getTotalIce(sixHoursCasts) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Legend
            :data="sixHoursCasts"
            :alerts="sixHoursCastsAlerts"
            :road="road"
          />
          <div
            v-if="(legends = getAlertsLegends(sixHoursCastsAlerts))"
            style="padding-left: 3% !important; font-size: 10px !important"
          >
            {{ legends.join(", ") }}
          </div>
        </div>
      </div>
      <div
        class="alerts-full part break-before"
        v-if="alerts && alerts.length > 0"
      >
        <div class="header-part">
          <h2>{{ $t("alerts.detailsTitle") }}</h2>
        </div>
        <div class="content-part">
          <table>
            <tbody>
              <tr v-for="(chunk, i) in alerts" :key="i">
                <td>
                  <h3>
                    {{ $t("alerts.title") }} : {{ i + 1 }}/{{ alerts.length }}
                  </h3>
                  <p>
                    <span
                      ><b>{{ $t("alerts.type") }} :</b>
                      {{ getAlertTypeText(chunk.type) }}</span
                    ><br />
                    <span
                      ><b>{{ $t("alerts.severity") }} : </b></span
                    >
                    <span>{{ getAlertSeverity(chunk.severity) }}</span>
                    <br />
                    <span
                      ><b>{{ $t("alerts.sourceDetailed") }} :</b>
                      {{ getAlertSource(chunk.source) }}</span
                    ><br />
                    <span
                      ><b>{{ $t("alerts.start") }} :</b>
                      {{
                        $moment(chunk.time_start * 1000).format(
                          "dddd DD MMM HH:mm"
                        )
                      }}</span
                    ><br />
                    <span
                      ><b>{{ $t("alerts.end") }} :</b>
                      {{
                        $moment(chunk.time_end * 1000).format(
                          "dddd DD MMM HH:mm"
                        )
                      }}</span
                    ><br />
                    <span v-if="chunk.message"
                      ><b>{{ $t("alerts.message") }} :</b></span
                    >
                  </p>
                  <p
                    :class="
                      chunk.severity > 2 && chunk.source == 'MG'
                        ? 'highlight-red'
                        : ''
                    "
                    v-html="chunk.message.replace(/(?:\r\n|\r|\n)/g, '<br>')"
                  ></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="loaded" v-if="this.ready"></div>
    </div>
  </div>
</template>

<script>
import cardinals from "../cardinals";
import { rc } from "@/road_cond";
import api from "../api";
import Legend from "../components/Legend";
import VueMarkdown from "vue-markdown";

export default {
  name: "Home",

  components: {
    Legend,
    VueMarkdown,
  },

  data: () => ({
    castpoint: null,
    castInfos: {},
    dateFormat: "ddd DD MMM HH:mm",
    sunArray: [],
    oneHourCasts: [],
    threeHoursCasts: [],
    sixHoursCasts: [],
    dayCasts: [],
    humanCast: null,
    setup: null,
    road: false,
    loaded: false,
    ready: false,
    alerts: null,
    alertTypes: null,
    alertSources: {
      fr: {
        MG: {
          short: "MG",
          long: "MétéoGlobale",
        },
        SMC: {
          short: "SMC",
          long: "Service météorologique du Canada",
        },
        MF: {
          short: "MF",
          long: "Météo France",
        }
      },
      en: {
        MG: {
          short: "MG",
          long: "MétéoGlobale",
        },
        SMC: {
          short: "MSC",
          long: "The Meteorological Service of Canada",
        },
        MF: {
          short: "MF",
          long: "Météo France",
        }
      },
    },
    alertSeverities: {
      fr: ["", "Mineure", "Modérée", "Forte", "Extrême"],
      en: ["", "Minor", "Moderate", "High", "Extreme"],
    },
    alertKindCols: {
      snowAcc: ["snow"],
      snowAccUrban: ["snow"],
      snowRate: ["snow"],
      snowRateRH: ["snow"],
      snowWind: ["snow", "w_avg"],
      rainFrzRate: ["rain_frz"],
    },
  }),

  computed: {
    lang: function() {
      return this.$route.query.lang ? this.$route.query.lang : "fr";
    },
    logoBrand: function() {
      if (!this.setup || (!this.setup.brand && !this.setup.brand_light))
        return "/img/logo-mg.png";

      const brand = this.setup.brand_light || this.setup.brand;
      return brand.match(/(\.svg)|(\.png)$/) ? brand : "/img/logo-mg.png";
    },
    alertsSevereOfTime: function() {
      const self = this;
      return function(timeEnd, duration) {
        const timeStart = timeEnd - duration * 3600;

        if (!this.alerts) return [];

        let alertsFiltered = this.alerts.filter(
          (a) =>
            a.severity >= 3 &&
            a.source == "MG" &&
            !(a.time_start >= timeEnd || a.time_end <= timeStart)
        );

        let props = new Set();
        alertsFiltered.map(
          (a) =>
            self.alertKindCols[a.type] &&
            self.alertKindCols[a.type].forEach((item) => props.add(item))
        );
        return Array.from(props);
      };
    },
    oneHourCastsAlerts() {
      return this.alerts
        ? this.alerts.filter(
            (alert) =>
              !(
                alert.time_start >
                  this.oneHourCasts[this.oneHourCasts.length - 1].time ||
                alert.time_end < this.oneHourCasts[0].time
              )
          )
        : null;
    },
    threeHoursCastsAlerts() {
      return this.alerts
        ? this.alerts.filter(
            (alert) =>
              !(
                alert.time_start >
                  this.threeHoursCasts[this.threeHoursCasts.length - 1].time ||
                alert.time_end < this.threeHoursCasts[0].time
              )
          )
        : null;
    },
    sixHoursCastsAlerts() {
      return this.alerts
        ? this.alerts.filter(
            (alert) =>
              !(
                alert.time_start >
                  this.sixHoursCasts[this.sixHoursCasts.length - 1].time ||
                alert.time_end < this.sixHoursCasts[0].time
              )
          )
        : null;
    },
    castInfosLabel() {
      let info = `lat: ${this.castInfos.point.lat} lon: ${this.castInfos.point.lon}`;

      if (this.castInfos.point.elev)
        info += ` alt: ${this.castInfos.point.elev}m`;

      return info;
    },
  },

  methods: {
    rc,
    alertsOn(cast) {
      let alert = this.alerts
        ? this.alerts.find((map) => map.time_end >= cast.time)
        : null;
      return alert;
    },

    getAlertsLegends(alerts) {
      let sources = [];

      alerts &&
        alerts.map((a) => {
          const source = this.alertSources[this.lang][a.source];
          console.log(source);
          if (
            source &&
            !sources.find((s) => s == source.short + ": " + source.long)
          )
            sources.push(source.short + ": " + source.long);
        });

      return sources;
    },

    getSetup: async function() {
      const { idSetup } = this.$route.params;

      if (idSetup) {
        try {
          this.setup = await api.setup.get(idSetup);
        } catch (err) {
          console.error(err.message);
        }
      }
    },

    getCastpoint: async function() {
      const { idSetup, idCastpoint } = this.$route.params;

      if (idCastpoint) {
        const res = await api.castpoint.getCastpoint(idSetup, idCastpoint);
        this.castpoint = res;
        if (this.castpoint && this.castpoint.timezone) {
          this.$moment.tz.setDefault(this.castpoint.timezone);
        }
      }
    },

    async getCasts() {
      try {
        const { idCastpoint } = this.$route.params;
        let time = this.$moment().unix();
        if (this.$route.query.time) {
          time = this.$route.query.time;
        }

        const [resOne, resThree, resSix, resDay] = await Promise.all([
          api.castpoint.getForecast(idCastpoint, 1, time),
          api.castpoint.getForecast(idCastpoint, 3, time),
          api.castpoint.getForecast(idCastpoint, 6, time),
          api.castpoint.getForecast(idCastpoint, 24, time),
        ]);

        this.castInfos = {
          point: resOne.point,
          updateOne: resOne.update.time,
          updateThree: resThree.update.time,
          updateSix: resSix.update.time,
        };

        this.sunArray = Object.values(resOne.sun);

        // One hour casts
        this.oneHourCasts = resOne.data.slice(0, 12);

        // Three hours casts
        this.threeHoursCasts = resThree.data.filter((chunk) => {
          return (
            this.$moment(chunk.time * 1000).diff(
              this.oneHourCasts[this.oneHourCasts.length - 1].time * 1000,
              "hours"
            ) >= 0
          );
        });

        this.threeHoursCasts = this.threeHoursCasts.slice(0, 12);

        // Six hours casts
        this.sixHoursCasts = resSix.data.filter((chunk) => {
          return (
            this.$moment(chunk.time * 1000).diff(
              this.threeHoursCasts[this.threeHoursCasts.length - 1].time * 1000,
              "hours"
            ) >= 0
          );
        });

        this.sixHoursCasts = this.sixHoursCasts.slice(0, 12);

        // 24h casts
        this.dayCasts = resDay;
      } catch (err) {
        console.error(err.message);
      }
    },

    async getAlerts() {
      this.alertTypes = await api.alert.getTypes(this.lang);

      let time = this.$moment().unix();
      if (this.$route.query.time) {
        time = this.$route.query.time;
      }

      try {
        const { idSetup, idCastpoint } = this.$route.params;

        if (idSetup && idCastpoint) {
          const res = await api.alert.get(
            idSetup,
            idCastpoint,
            time,
            this.lang
          );
          this.alerts = res;
        }
      } catch (err) {
        console.error(err.message);
      }
    },

    async getHumanCast() {
      try {
        let time = this.$moment().unix();
        if (this.$route.query.time) {
          time = this.$route.query.time;
        }

        const { idSetup, idCastpoint } = this.$route.params;
        const res = await api.humancast.getOfCastpoint(
          idSetup,
          idCastpoint,
          time
        );

        this.humanCast = res;
      } catch (err) {
        console.error(err.message);
      }
    },
    isRise(chunk) {
      let sunarray = this.sunArray.find((map) => {
        let diff = chunk.time - map.rise;
        return diff > 0 && diff < chunk.duration * 3600;
      });
      if (!sunarray) return "";
      return `<span class="sun"><img width="20px" src="/sunrise.svg"> ${this.$moment(
        sunarray.rise * 1000
      ).format("HH:mm")}</span>`;
    },
    isSet(chunk) {
      let sunarray = this.sunArray.find((map) => {
        let diff = chunk.time - map.set;
        return diff > 0 && diff < chunk.duration * 3600;
      });
      if (!sunarray) return "";
      return `<span class="sun"><img width="20px" src="/sunset.svg"> ${this.$moment(
        sunarray.set * 1000
      ).format("HH:mm")}</span>`;
    },
    getTotalRain(castsArray) {
      let res = 0;
      castsArray.forEach((chunk) => (res += chunk.rain));
      return this.roundToTwo(res);
    },

    getTotalRainFrz(castsArray) {
      let res = 0;
      castsArray.forEach((chunk) => (res += chunk.rain_frz));
      return this.roundToTwo(res);
    },

    getTotalIce(castsArray) {
      let res = 0;
      castsArray.forEach((chunk) => (res += chunk.ice));
      return this.roundToTwo(res);
    },

    getTotalSnow(castsArray) {
      let res = 0;
      castsArray.forEach((chunk) => (res += chunk.snow));
      return this.roundToTwo(res);
    },

    roundToTwo: function(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },

    getAlertTypeText: function(type) {
      const alertType = this.alertTypes.find((at) => at.id == type);
      return alertType && alertType.description
        ? alertType.description
        : "notset";
    },

    getAlertSeverity: function(severity) {
      return this.alertSeverities[this.lang][severity];
    },

    getAlertSource: function(source, attr = "long") {
      return this.alertSources[this.lang][source.toUpperCase()] &&
        this.alertSources[this.lang][source.toUpperCase()][attr]
        ? this.alertSources[this.lang][source.toUpperCase()][attr]
        : "not found";
    },

    iconUrl: function(ts, night = false) {
      let code = null;
      let suffix = night ? "n" : "d";

      if (ts == 0) code = "01" + suffix; // soleil
      if (ts == 1) code = "02" + suffix; // soleil + 1 petit nuage blanc
      if (ts == 2) code = "03" + suffix; // soleil + 1 gros nuage blanc
      if (ts == 3) code = "04"; // nuage blanc
      if (ts == 4) code = "04"; // nuage blanc
      if (ts == 5) code = "46"; // 2 gouttes
      if (ts == 6) code = "05" + suffix; // soleil, nuage gris, 3 gouttes
      if (ts == 7) code = "09"; // nuage gris+, 3 gouttes
      if (ts == 8) code = "41" + suffix; // soleil, nuage gris, 3 gouttes
      if (ts == 9) code = "13"; // 3 flocons
      if (ts == 10) code = "07" + suffix; // éclaircies, 1 flocon, 2 gouttes
      if (ts == 11) code = "15"; // brouillard
      if (ts == 12) code = "06" + suffix; // orage + 3 gouttes
      if (ts == 13) code = "12"; // mélange pluie/neige
      if (ts == 14) code = "13"; // pluie verglaçante
      if (ts == 15) code = "3"; // grele

      if (ts >= 0 && ts <= 13)
        return (
          "https://cdn.jsdelivr.net/gh/YR/weather-symbols@6.0.2/dist/svg/" +
          code +
          ".svg"
        );
      else if (ts >= 14)
        return "https://worldweather.wmo.int/images/" + code + ".png";
    },

    cardinals,
  },

  async mounted() {
    if (process.env.VUE_APP_API_URI) api.setApiUrl(process.env.VUE_APP_API_URI);
    else if (this.$route.query.apiurl) api.setApiUrl(this.$route.query.apiurl);

    if (process.env.VUE_APP_API_TOKEN)
      api.setToken(process.env.VUE_APP_API_TOKEN);
    else if (this.$route.query.token) api.setToken(this.$route.query.token);

    this.$i18n.locale = this.$route.query.lang ? this.$route.query.lang : "fr";
    this.$moment.locale(this.$i18n.locale);

    this.road = parseInt(this.$route.query.road) !== 0;

    await this.getSetup();
    await this.getCastpoint();
    await this.getCasts();
    await this.getAlerts();
    await this.getHumanCast();

    this.loaded = true;
    this.$nextTick(() => {
      this.ready = true;
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

html {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.home {
  width: 96%;
  margin: 0 auto;
}

h1,
h2 {
  margin: 0;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

h2 small {
  font-weight: 400;
  font-size: 0.65em;
}

p {
  margin: 5px 0;
}

table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  overflow: hidden;
  text-align: left;
}

table.alerts th {
  font-size: 0.8em;
}

td,
th {
  padding: 3px 6px;
}

.highlight-red {
  color: red;
  font-weight: bold;
}

.highlight-orange {
  color: darkorange;
  font-weight: bold;
}

.highlight-blue {
  color: #0f7b9d;
  font-weight: bold;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  height: 80px;

  img {
    height: 100%;
  }
}

.header > div:nth-child(1),
.header > div:nth-child(3) {
  flex: 0 1 auto;
  align-self: flex-start;
  height: 100%;
}

.header div:nth-child(2) {
  flex: 1 0 auto;
  padding-left: 10px;
}

.part {
  margin-bottom: 45px;
}

.part .text-part p {
  font-size: 1.25em;
}

.casts .content-part > table {
  height: 900px;
}

.casts .content-part .title {
  padding-bottom: 16px;
  padding-top: 16px;
  border-right-width: 0;
  font-size: 24px;
}

.casts .content-part .maj {
  text-align: right;
  font-size: 16px;
  border-left-width: 0;
}

.casts .content-part .title span {
  vertical-align: middle;
}

.casts .content-part .bullet {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.oneHourCasts .bullet {
  background-color: #b3d161;
}

.threeHourCasts .bullet {
  background-color: #72caf0;
}

.sixHourCasts .bullet {
  background-color: #ef7550;
}

.casts-alerts p {
  margin-top: 0px;
  margin-bottom: 6px;
}

.casts-alerts h3 {
  margin-bottom: 4px;
  margin-top: 4px;
  font-size: 1em;
}

.casts-alerts .casts-alert__header {
  font-size: 0.7em;
}

.casts-alerts .casts-alert__value {
  font-size: 0.9em;
  margin-right: 10px;
}

.casts-alerts .casts-alert__severe {
  font-size: 0.8em;
  font-weight: bold;
  color: red;
}

.alerts-full .content-part table {
  text-align: left;
  text-align: left;
}

.alerts-full .content-part table td span {
  line-height: 1.8em;
}

.cast-picto {
  width: 38px;
}

.unit--small {
  font-size: 10px;
}

.table-header {
  background: rgba(114, 202, 240, 0.4);
}

.alerts.part .table-header td {
  vertical-align: center;
}

.alert-card {
  background: rgb(255, 248, 146);
  border-radius: 20px;
  padding: 10px 30px;
  width: 100%;
}

.sun {
  display: flex;
  align-items: center;
  justify-content: center;
}

@page {
  margin: 10px;
}

@media print {
  * {
    max-width: 100%;
  }

  body {
    margin: 0;
  }

  header,
  footer,
  nav,
  aside {
    display: none;
  }

  .break-before {
    break-before: page;
    -webkit-break-before: page;
    -webkit-column-break-before: page;
  }

  table,
  .part {
    break-inside: avoid;
  }

  .alerts-full.part,
  .alerts-full.part table {
    break-inside: auto;
  }

  .alerts-full.part table tr {
    break-inside: avoid;
  }
}
</style>
