export default deg => {
	const cardinals = [
		'N',
		'NNE',
		'NE',
		'ENE',
		'E',
		'ESE',
		'SE',
		'SSE',
		'S',
		'SSW',
		'SW',
		'WSW',
		'W',
		'WNW',
		'NW',
		'NNW',
	];
	if (!deg || deg == 999) return '-';
	var idx = deg === 360 ? 0 : Math.round(deg / 22.5) % 16;
	return cardinals[idx];
};
